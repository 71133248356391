var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('data-table', _vm._b({
    ref: "table",
    attrs: {
      "table-id": "CompareBySKU",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "c_id",
      "selectable": _vm.selectable,
      "paginatable": _vm.paginatable,
      "configurable": _vm.configurable
    },
    on: {
      "selectAction": function ($event) {
        return _vm.$emit('selectAction', $event);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.diffs, function (i) {
      return {
        key: i,
        fn: function ({
          item
        }) {
          return [_c('span', {
            key: i,
            staticClass: "compare-item",
            class: {
              red: Number(item[i]) < 0,
              green: Number(item[i]) > 0
            }
          }, [_vm._v(_vm._s(item[i]))])];
        }
      };
    })], null, true)
  }, 'data-table', _vm.$attrs, false))], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };