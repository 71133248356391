import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      period1: "",
      period2: "",
      diffs: ["total_orders_diff", "sum_orders_diff", "total_sales_diff", "sum_finished_price_sales_diff", "total_return_sales_diff", "sum_finished_price_return_sales_diff", "sum_comission_sales_diff", "sum_revenue_sales_diff", "sum_price_orders_diff", "sum_price_sales_diff", "sum_price_return_sales_diff"]
    };
  },
  methods: {
    async tableLoader({
      query
    }) {
      //console.log('tableLoader().STR: query=', query)

      let split = this.reportSettings.date1_1.split("-");
      const date1_1 = split[1] + "-" + split[0] + "-" + split[2];
      split = this.reportSettings.date1_2.split("-");
      const date1_2 = split[1] + "-" + split[0] + "-" + split[2];
      split = this.reportSettings.date2_1.split("-");
      const date2_1 = split[1] + "-" + split[0] + "-" + split[2];
      split = this.reportSettings.date2_2.split("-");
      const date2_2 = split[1] + "-" + split[0] + "-" + split[2];
      this.period1 = date1_1 + " - " + date1_2;
      this.period2 = date2_1 + " - " + date2_2;

      //console.log('tableLoader(): ->tableLoaderCreator()')

      const data = await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbCompareBySKU", "connections/getOzCompareBySKU", {}, {});

      //console.log('tableLoader().110: data=', data)

      const fields = ["sum_comission_sales1", "sum_comission_sales2", "sum_comission_sales_diff", "sum_finished_price_return_sales1", "sum_finished_price_return_sales2", "sum_finished_price_return_sales_diff", "sum_finished_price_sales1", "sum_finished_price_sales2", "sum_finished_price_sales_diff", "sum_orders1", "sum_orders2", "sum_orders_diff", "sum_revenue_sales1", "sum_revenue_sales2", "sum_revenue_sales_diff", "totalPrice", "total_orders1", "total_orders2", "total_orders_diff", "total_return_sales1", "total_return_sales2", "total_return_sales_diff", "total_sales1", "total_sales2", "total_sales_diff"];
      try {
        var _data$items;
        (_data$items = data.items) === null || _data$items === void 0 || _data$items.map(item => {
          fields.forEach(field => {
            if (!Number.isInteger(Number(item[field]))) {
              var _Number;
              item[field] = (_Number = Number(item[field])) === null || _Number === void 0 ? void 0 : _Number.toFixed(2);
            } else {
              item[field] = Number(item[field]);
            }
          });
        });
      } catch (e) {
        //console.log('tableLoader().151: e=',e)
      }

      //console.log('tableLoader().END: data=', data)

      return data;
    }
  },
  computed: {
    columns() {
      // const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2)
      return this.$store.state.connections.marketplace.title === "Wildberries" ? [{
        title: "SKU",
        show: true,
        name: "nmid",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: "Артикул",
        show: true,
        name: "supplierArticle",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Бренд",
        show: true,
        name: "brand",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Наименование",
        show: true,
        name: "subject",
        type: "text",
        width: 200,
        filter: "text",
        getImage: item => item.image
      }, {
        title: "Размер",
        show: true,
        name: "techSize",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Категория",
        show: true,
        name: "category",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Штрих-код",
        show: true,
        name: "Barcode",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: `Количество заказов (${this.period1})`,
        show: true,
        name: "total_orders1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Количество заказов (разница)",
        show: true,
        name: "total_orders_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Количество заказов (${this.period2})`,
        show: true,
        name: "total_orders2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по заказам (${this.period1})`,
        show: true,
        name: "sum_orders1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Cумма по заказам (разница)",
        show: true,
        name: "sum_orders_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по заказам (${this.period2})`,
        show: true,
        name: "sum_orders2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Количество продаж (${this.period1})`,
        show: true,
        name: "total_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Количество продаж (разница)",
        show: true,
        name: "total_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Количество продаж (${this.period2})`,
        show: true,
        name: "total_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по продажам (${this.period1})`,
        show: true,
        name: "sum_finished_price_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Cумма по продажам (разница)",
        show: true,
        name: "sum_finished_price_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по продажам (${this.period2})`,
        show: true,
        name: "sum_finished_price_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Количество возвратов (${this.period1})`,
        show: true,
        name: "total_return_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Количество возвратов (разница)",
        show: true,
        name: "total_return_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Количество возвратов (${this.period2})`,
        show: true,
        name: "total_return_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по возвратам (${this.period1})`,
        show: true,
        name: "sum_finished_price_return_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Cумма по возвратам (разница)",
        show: true,
        name: "sum_finished_price_return_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по возвратам (${this.period2})`,
        show: true,
        name: "sum_finished_price_return_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма комиссии WB (${this.period1})`,
        show: true,
        name: "sum_comission_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Cумма комиссии WB (разница)",
        show: true,
        name: "sum_comission_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма комиссии WB (${this.period2})`,
        show: true,
        name: "sum_comission_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Выручка (${this.period1})`,
        show: true,
        name: "sum_revenue_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Выручка (разница)",
        show: true,
        name: "sum_revenue_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Выручка (${this.period2})`,
        show: true,
        name: "sum_revenue_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }] : [{
        title: "SKU",
        show: true,
        name: "sku",
        type: "text",
        width: 140,
        filter: "text",
        noOverflow: true
      }, {
        title: "Артикул",
        show: true,
        name: "offer_id",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Наименование",
        show: true,
        name: "name",
        type: "text",
        width: 500,
        filter: "text",
        getImage: item => item.image
      }, {
        title: "Категория",
        show: true,
        name: "category_name",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Штрих-код",
        show: true,
        name: "barcode",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: `Количество заказов (${this.period1})`,
        show: true,
        name: "total_orders1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Количество заказов (разница)",
        show: true,
        name: "total_orders_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Количество заказов (${this.period2})`,
        show: true,
        name: "total_orders2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по заказам (${this.period1})`,
        show: true,
        name: "sum_price_orders1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Cумма по заказам (разница)",
        show: true,
        name: "sum_price_orders_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по заказам (${this.period2})`,
        show: true,
        name: "sum_price_orders2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Количество продаж (${this.period1})`,
        show: true,
        name: "total_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Количество продаж (разница)",
        show: true,
        name: "total_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Количество продаж (${this.period2})`,
        show: true,
        name: "total_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по продажам (${this.period1})`,
        show: true,
        name: "sum_price_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Cумма по продажам (разница)",
        show: true,
        name: "sum_price_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по продажам (${this.period2})`,
        show: true,
        name: "sum_price_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Количество возвратов (${this.period1})`,
        show: true,
        name: "total_return_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Количество возвратов (разница)",
        show: true,
        name: "total_return_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Количество возвратов (${this.period2})`,
        show: true,
        name: "total_return_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по возвратам (${this.period1})`,
        show: true,
        name: "sum_price_return_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Cумма по возвратам (разница)",
        show: true,
        name: "sum_price_return_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма по возвратам (${this.period2})`,
        show: true,
        name: "sum_price_return_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма комиссии WB (${this.period1})`,
        show: true,
        name: "sum_comission_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Cумма комиссии WB (разница)",
        show: true,
        name: "sum_comission_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Cумма комиссии WB (${this.period2})`,
        show: true,
        name: "sum_comission_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Выручка (${this.period1})`,
        show: true,
        name: "sum_revenue_sales1",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Выручка (разница)",
        show: true,
        name: "sum_revenue_sales_diff",
        type: "slot",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: `Выручка (${this.period2})`,
        show: true,
        name: "sum_revenue_sales2",
        type: "text",
        width: 350,
        filter: "numberRange",
        noOverflow: true
      }];
    }
  }
};