var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('data-table', _vm._b({
    ref: "table",
    attrs: {
      "table-id": "Compare",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "c_id",
      "selectable": _vm.selectable,
      "paginatable": _vm.paginatable,
      "configurable": _vm.configurable
    },
    on: {
      "selectAction": function ($event) {
        return _vm.$emit('selectAction', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "diff",
      fn: function ({
        item
      }) {
        return [_c('span', {
          staticClass: "compare-item",
          class: {
            red: Number(item.diff) < 0,
            green: Number(item.diff) > 0
          }
        }, [_vm._v(_vm._s(item.diff))])];
      }
    }])
  }, 'data-table', _vm.$attrs, false))], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };