var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('WarningAllLK'), _c('has-tokens', [!_vm.loading ? _c('report-page', [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "highlights__filters"
        }, [_vm.hasOzon && _vm.hasWb ? _c('app-select', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_marketplace_items,
            "fullwidth": ""
          },
          model: {
            value: _vm.marketplace,
            callback: function ($$v) {
              _vm.marketplace = $$v;
            },
            expression: "marketplace"
          }
        }) : _vm._e(), _c('app-select-multi', {
          staticClass: "highlights__select",
          attrs: {
            "items": _vm.select_tokens_items
          },
          model: {
            value: _vm.selectedTokens,
            callback: function ($$v) {
              _vm.selectedTokens = $$v;
            },
            expression: "selectedTokens"
          }
        }), _c('div', {
          staticClass: "calendar-wrap"
        }, [_c('span', {
          staticClass: "calendar-label"
        }, [_vm._v("Период")]), _vm.calendar ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.$store.state.connections.calendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e()], 1), _c('span', {
          staticClass: "settings__tooltip"
        }, [_c('img', {
          staticClass: "swap-periods",
          attrs: {
            "src": require("../assets/images/icons/swap.svg"),
            "alt": "Swap Icon"
          },
          on: {
            "click": _vm.swapPeriods
          }
        }), _c('span', {
          staticClass: "settings__tooltip__content"
        }, [_vm._v(" Поменять местами ")])]), _c('div', {
          staticClass: "calendar-wrap"
        }, [_c('span', {
          staticClass: "calendar-label"
        }, [_vm._v("Сравнить с")]), _vm.calendar2 ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.$store.state.connections.calendarBoundaries
          },
          model: {
            value: _vm.calendar2,
            callback: function ($$v) {
              _vm.calendar2 = $$v;
            },
            expression: "calendar2"
          }
        }) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }], null, false, 1228301991)
  }, [_vm._v(" Сравнение периодов ")]), _c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs_tables
    },
    model: {
      value: _vm.tabs_tables_model,
      callback: function ($$v) {
        _vm.tabs_tables_model = $$v;
      },
      expression: "tabs_tables_model"
    }
  }, [_c('fade-transition', [_vm.tabs_tables_model === _vm.tabs_tables[1] ? _c('app-select', {
    attrs: {
      "items": _vm.select_chart_items
    },
    model: {
      value: _vm.select_chart_model,
      callback: function ($$v) {
        _vm.select_chart_model = $$v;
      },
      expression: "select_chart_model"
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mt32"
  }, [_vm.tabs_tables_model === _vm.tabs_tables[0] && _vm.reportSettings ? _c('compare-table', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings
    }
  }) : _vm._e(), _c('div', {
    staticClass: "cat-info__chart"
  }, [_vm.tabs_tables_model === _vm.tabs_tables[1] ? _c('large-chart', {
    attrs: {
      "datasets": _vm.chartDatasets,
      "period": _vm.calendar,
      "period2": _vm.calendar2
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mt32",
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between"
    }
  }, [_c('main-title', [_c('span', [_vm._v(" Детализация по товарам ")])]), _c('div', {
    staticClass: "table-filters"
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettings ? _c('compare-table-by-s-k-u', {
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "paginatable": true,
      "configurable": true,
      "fileName": _vm.fileName
    }
  }) : _vm._e()], 1)], 1) : _c('div', [_c('data-loading', {
    attrs: {
      "title": 'Сейчас мы загружаем данные вашего магазина',
      "image": require('@/assets/images/wireframe.jpg'),
      "text1": 'На период загрузки отчеты пока недоступны, обычно этот процесс занимает 4-8 часов. Попробуйте вернуться позже в этот раздел.',
      "text2": 'Также по завершении загрузки мы отправим оповещение на ваш email'
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };